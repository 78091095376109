import React, { useContext } from "react";
import GeneralSidebar from "./GeneralSidebar";

export default function ShortAnswerSidebar(props: any) {
  return (
    <>
      <GeneralSidebar />
    </>
  );
}
